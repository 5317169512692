import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';

import { ConfigApiService } from './config-api.service';
import { SetUi } from './config.actions';

@Injectable({ providedIn: 'root' })
export class ConfigService {

  private readonly store = inject(Store);
  private readonly api = inject(ConfigApiService);

  public getUi() {
    return this.api.getUi().pipe(
      tap(ui => this.store.dispatch(new SetUi(ui))),
    );
  }
}
