export enum FeatureFlag {
  Off = 'off',
  On = 'on',
  Blurred = 'blurred',
}

export type Features = {
  chart: FeatureFlag;
  chat: FeatureFlag;
};
